var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('BaseTable',{staticClass:"table-shadow",attrs:{"headers":_vm.headers,"items":_vm.info,"loading":_vm.loading,"paginate":true,"lastPage":_vm.nLastPage},on:{"pageChange":function($event){return _vm.fetchResumoVeiculo(_vm.filters, $event)}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Resumo")]),_c('v-spacer'),_c('Loader',{attrs:{"active":_vm.carregar,"message":"Gerando relatório..."}}),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.fetchReport(_vm.info.item)}}},[_c('v-icon',[_vm._v("mdi-file-pdf-box ")]),_vm._v(" Gerar PDF ")],1),_c('FilterResumoConsultorVendas',{on:{"selectedFilters":function($event){_vm.fetchResumoVeiculo((_vm.filters = $event))}}})],1)]},proxy:true},{key:"item.id_band",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"color":_vm.bandeiras[item.id_band].color,"dark":""}},[_vm._v(" "+_vm._s(_vm.bandeiras[item.id_band].icon)+" ")])]}},{key:"item.nome",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id_colaborador)+" - "+_vm._s(_vm._f("TitleCase")(item.nome))+" ")]}},{key:"item.funcao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.cod_funcao)+" - "+_vm._s(_vm._f("TitleCase")(item.funcao))+" ")]}},{key:"item.valor_custo_final",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("BrazilianCurrency")(item.valor_custo_final))+" ")]}},{key:"item.valor_vendido",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("BrazilianCurrency")(item.valor_vendido))+" ")]}},{key:"item.total_comissao",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("BrazilianCurrency")(item.total_comissao))+" ")]}},{key:"item.chassi",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.chassi_completo)+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }