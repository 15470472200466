<template>
  <v-container class="container-card">
    <v-card>
      <ResumoConsultorVendas />
    </v-card>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import ResumoConsultorVendas from "@/components/comissao/deptoComercial/ResumoConsultorVendas";
export default {
  components: {
    ResumoConsultorVendas
  },

  methods: {
    ...mapActions({
      fetchView: "sistema/fetchView"
    })
  },

  mounted() {
    this.fetchView("Consultor de Vendas");
  }
};
</script>

<style></style>
