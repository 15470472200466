<template>
  <div>
    <BaseTable
      class="table-shadow"
      :headers="headers"
      :items="info"
      :loading="loading"
      :paginate="true"
      :lastPage="nLastPage"
      @pageChange="fetchResumoVeiculo(filters, $event)"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Resumo</v-toolbar-title>
          <v-spacer></v-spacer>
          <Loader :active="carregar" message="Gerando relatório..." />
          <v-btn text @click="fetchReport(info.item)">
            <v-icon>mdi-file-pdf-box </v-icon>
            Gerar PDF
          </v-btn>
          <FilterResumoConsultorVendas
            @selectedFilters="fetchResumoVeiculo((filters = $event))"
          />
        </v-toolbar>
      </template>
      <template v-slot:[`item.id_band`]="{ item }">
        <v-icon :color="bandeiras[item.id_band].color" dark>
          {{ bandeiras[item.id_band].icon }}
        </v-icon>
      </template>
      <template v-slot:[`item.nome`]="{ item }">
        {{ item.id_colaborador }} - {{ item.nome | TitleCase }}
      </template>
      <template v-slot:[`item.funcao`]="{ item }">
        {{ item.cod_funcao }} - {{ item.funcao | TitleCase }}
      </template>
      <template v-slot:[`item.valor_custo_final`]="{ item }">
        {{ item.valor_custo_final | BrazilianCurrency }}
      </template>
      <template v-slot:[`item.valor_vendido`]="{ item }">
        {{ item.valor_vendido | BrazilianCurrency }}
      </template>
      <template v-slot:[`item.total_comissao`]="{ item }">
        {{ item.total_comissao | BrazilianCurrency }}
      </template>
      <template v-slot:[`item.chassi`]="{ item }">
        {{ item.chassi_completo }}
      </template>
    </BaseTable>
  </div>
</template>

<script>
import jasperService from "@/services/http/jasperService";
import Loader from "@/components/shared/LoaderCometa";
import comissao from "@/services/http/comissaoService";
import BaseTable from "@/components/shared/NewBaseTable";
import FilterResumoConsultorVendas from "./FilterResumoConsultorVendas";
import banderiras from "@/mixins/bandeiras";
import currencyMixin from "@/mixins/currencyMixin";

export default {
  name: "TabelaEquipe",

  components: {
    BaseTable,
    FilterResumoConsultorVendas,
    Loader
  },

  mixins: [banderiras, currencyMixin],

  data() {
    return {
      loading: false,
      filters: "",
      carregar: false,
      nLastPage: 0,
      headers: [
        { text: "Segmento", value: "id_band", align: "center" },
        { text: "Apelido", value: "apelido" },
        { text: "Colaborador", value: "nome" },
        { text: "Função", value: "funcao" },
        { text: "Valor Custo", value: "valor_custo_final", align: "right" },
        { text: "Valor Venda", value: "valor_vendido", align: "right" },
        { text: "Total Comissão", value: "total_comissao", align: "center" },
        { text: "Tipo Venda", value: "tipo_venda", align: "center" },
        { text: "Chassi", value: "chassi", align: "center" }
        // { text: "Data Venda", value: "total_comissao", align: "center" }
      ],
      info: []
    };
  },

  methods: {
    async fetchResumoVeiculo(filters, nPage = 1) {
      this.loading = true;
      let { data } = await comissao()
        .resumoVeiculo()
        .show({
          page: nPage,
          ativo: "S",
          ...filters
        });
      this.nLastPage = data.last_page;
      this.loading = false;
      this.info = data.data;
    },
    generatePdf(data) {
      var blob = new Blob([data], { type: "application/pdf" });
      var blobURL = URL.createObjectURL(blob);
      window.open(blobURL);
    },

    async fetchReport() {
      this.carregar = true;
      let { data } = await jasperService()
        .comissao()
        .resumoVendedor()
        .show({
          in_id_colaborador: this.filters.id_colaborador,
          in_id_funcao: this.filters.id_funcao,
          in_valor_custo_final: this.filters.valor_custo_final,
          in_valor_vendido: this.filters.valor_vendido,
          in_total_comissao: this.filters.total_comissao,
          in_tipo_venda: this.filters.tipo_venda,
          in_mes_ref: this.filters.mes_ref,
          in_ano_ref: this.filters.ano_ref
        });
      this.carregar = false;
      this.generatePdf(data);
    }
  },

  mounted() {
    this.fetchResumoVeiculo();
  }
};
</script>

<style></style>
