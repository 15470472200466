var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"320","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('FilterBottom',_vm._g({},on))]}}])},[_c('v-card',[_c('v-container',[_c('BaseFilter',{attrs:{"prepend-inner-icon":"mdi-file-table-box-multiple-outline","clearable":"","single-line":"","label":"Segmento","item-text":"descricao","item-value":"id_band","service":"sistemaService.tipoBandeiras","filters":{ id_band: '2,4' },"isCompany":true},on:{"change":function($event){return _vm.handleFilter()}},model:{value:(_vm.filters.id_band),callback:function ($$v) {_vm.$set(_vm.filters, "id_band", $$v)},expression:"filters.id_band"}}),_c('BaseFilter',{attrs:{"prepend-inner-icon":"mdi-domain","clearable":"","single-line":"","label":"Empresa","item-text":"apelido","item-value":"id_empresa","service":"sistemaService.empresa","filters":{
          id_band: _vm.filters.id_band,
          calcula_comissao: 'S'
        },"isCompany":true},on:{"change":function($event){return _vm.handleFilter()}},model:{value:(_vm.filters.id_empresa),callback:function ($$v) {_vm.$set(_vm.filters, "id_empresa", $$v)},expression:"filters.id_empresa"}}),_c('BaseFilter',{attrs:{"label":"Departamento","service":"comissaoService.departamentos","item-text":"departamento","item-value":"id_depto","single-line":"","prepend-inner-icon":"mdi-toolbox","clearable":"","multiple":false},on:{"change":function($event){return _vm.handleFilter()}},model:{value:(_vm.filters.id_depto),callback:function ($$v) {_vm.$set(_vm.filters, "id_depto", $$v)},expression:"filters.id_depto"}}),_c('BaseFilter',{attrs:{"prepend-inner-icon":"mdi-account","clearable":"","single-line":"","label":"Função","item-text":"descricao","item-value":"id_funcao","service":"sistemaService.funcoes","filters":{ id_band: _vm.filters.id_band, ativo: 'S' },"hasCode":true},on:{"change":function($event){return _vm.handleFilter()}},model:{value:(_vm.filters.id_funcao),callback:function ($$v) {_vm.$set(_vm.filters, "id_funcao", $$v)},expression:"filters.id_funcao"}}),_c('BaseFilter',{attrs:{"prepend-inner-icon":"mdi-account","clearable":"","single-line":"","label":"Colaboradores","item-text":"colaborador","item-value":"id_colaborador","service":"sistemaService.colaboradorEmpresa","filters":{
          id_empresa: _vm.filters.id_empresa,
          id_funcao: _vm.filters.id_funcao,
          id_band: _vm.filters.id_band,
          id_depto: _vm.filters.id_depto,
          ativo: 'S'
        },"hasCode":true},on:{"change":function($event){return _vm.handleFilter()}},model:{value:(_vm.filters.id_colaborador),callback:function ($$v) {_vm.$set(_vm.filters, "id_colaborador", $$v)},expression:"filters.id_colaborador"}}),_c('DatePickerMonth',{on:{"date":function($event){return _vm.assignDateFilter($event)}}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }